
.footer__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4rem 0;
}

.footer__top div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__top h6 {
  font-size: small;
  margin: 0;
  margin-bottom: .6rem;
}

.footer__top a {
  font-size: small;
  margin-bottom: .4rem;
}

.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__bottom img {
  padding: 1.4rem 0;
  width: 9rem;
}

.footer__bottom p {
  font-size: small;
}

/* mobile */
@media only screen and (max-width: 500px) { 
  .footer__top {
    display: none;
  }

  .footer__bottom {
    border: 0;
    flex-direction: column;
  }
}