.description__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
}

.description__content div {
  width: 45%;
}

.description__content h2 {
  font-size: 32pt;
  line-height: 38pt;
  margin: 0;
}

.description__content p {
  width: 60%;
}

.description__subtitle {
  color: #f8ff3e;
  font-weight: 600;
  letter-spacing: 0.4rem;
  font-size: 10pt;
}

.description__content img {
  width: min(50%, 50vh);
}

.description__button {
  display: block;
  width: fit-content;
  color: white;
  text-decoration: none;
  background-color: #f501fb;
  font-size: small;
  font-weight: 500;
  padding: 0.8rem 3.5rem;
  border-radius: 0.3rem;
  margin-top: 1.3rem;
}

/* tablet */
@media only screen and (max-width: 900px) {
  .description__content p {
    width: 100%;
  }
}

/* mobile */
@media only screen and (max-width: 500px) { 
  .description__content {
    flex-direction: column-reverse;
  }

  .description__content__reverse {
    flex-direction: column;
  }

  .description__content img, .description__content div {
    width: 100%;
  }

  .description__subtitle {
    margin-top: 3rem;
    letter-spacing: 0.2rem;
    font-size: 9pt;
  }

  .description__content h2 {
    font-size: 26pt;
    line-height: 32pt;
    font-weight: 600;
  }
}
