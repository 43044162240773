header {
  position: fixed;
  background: #161616;
  background: radial-gradient(circle at bottom, #2d2d2d 0%, #161616 60%);
  z-index: 10;
  width: 100vw;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header img {
  width: min(12vw, 16vh);
  margin: 1.4rem 0;
}

header nav a {
  color: white;
  text-decoration: none;
  margin-left: 1.4rem;
  font-size: small;
}

.header__border {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100vw;
  background: #fd63ae;
  background: linear-gradient(90deg, #fc56aa 0%, #f92be2 50%, #8720ec 100%);
}

/* mobile */
@media only screen and (max-width: 500px) { 
  header nav {
    display: none;
  }

  header img {
    width: 30vw;
    margin: 1.4rem auto;
  }

  .header__container {
    justify-content: center;
  }
}
