.bottom {
  margin-top: 6rem;
  text-align: center;
  padding-bottom: 4rem;
}

.bottom h2 {
  margin-top: -3rem;
  font-size: 32pt;
  line-height: 38pt;
}

.bottom p {
  width: min(50%, 800px);
  margin: 0 auto;
}

.bottom__coin {
  width: min(14vw, 300px);
  display: block;
  margin: 0 auto;
  transform: translate(0, -35%);
}

.bottom__button {
  display: block;
  width: fit-content;
  color: white;
  text-decoration: none;
  background-color: #191917;
  font-size: small;
  font-weight: 500;
  padding: 0.8rem 3.5rem;
  border-radius: 0.3rem;
  margin: 2rem 1rem;
}

.bottom__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* tablet */
@media only screen and (max-width: 900px) {
  .bottom h2 {
    margin-top: -1rem;
  }
}

/* mobile */
@media only screen and (max-width: 500px) {
  .bottom__coin {
    width: 28vw;
  }

  .bottom h2 {
    margin-top: 0;
    font-size: 26pt;
    line-height: 32pt;
    font-weight: 600;
  }

  .bottom__buttons {
    flex-direction: column;
  }

  .bottom__button {
    margin: 1rem 1rem;
  }
}
