.landing {
  position: relative;
  background: #161616;
  background: radial-gradient(circle at top, #2d2d2d 0%, #161616 60%);
  height: 100vh;
  overflow: hidden;
}

.landing__subtitle {
  margin-top: 10%;

  text-align: center;
  color: #f8ff3e;
  font-weight: 600;
  letter-spacing: 0.4rem;
  font-size: 10pt;
}

.landing__title {
  text-align: center;
  color: white;
  font-size: 40pt;
  line-height: 48pt;
  font-weight: 600;
  margin: 0.3rem;
}

.landing__text {
  color: white;
  text-align: center;
}

.landing__buttons {
  margin: 0 auto;
  margin-top: 2.5rem;
  width: fit-content;
}

.landing__button__border {
  color: white;
  text-decoration: none;
  border: 0.5px solid white;
  background-color: #161616;
  font-size: small;
  font-weight: 500;
  padding: 0.8rem 2.5rem;
  border-radius: 0.3rem;
}

.landing__button__full {
  color: white;
  text-decoration: none;
  background-color: #f501fb;
  font-size: small;
  font-weight: 500;
  padding: 0.8rem 3.5rem;
  border-radius: 0.3rem;
  margin-left: 1rem;
}

.landing__content {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.landing__background {
  width: 100vw;
  position: absolute;
  bottom: -10vw;
  z-index: 0;
}

.landing__coin__left {
  position: absolute;
  left: 4%;
  top: 53%;
  transform: translate(0, -50%);
  width: 33%;
}

.landing__coin__right {
  position: absolute;
  right: 0;
  top: 58%;
  transform: translate(0, -50%);
  width: 45%;
}

.landing__arrow {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 20px;
  transition: .3s;
}

.landing__arrow:hover{
  cursor: pointer;
  transform: translate(-50%, 30px);
}

/* tablet */
@media only screen and (max-width: 900px) {
  .landing__subtitle {
    margin-top: 30%;
  }

  .landing__background {
    bottom: 0;
  }

  .landing__coin__left {
    top: 75%;
  }
  
  .landing__coin__right {
    top: 70%;
  }
}

/* mobile */
@media only screen and (max-width: 500px) { 
  .landing__background {
    width: 150vw;
  }

  .landing__coin__left {
    top: 80%;
  }
  
  .landing__coin__right {
    top: 75%;
  }

  .landing__subtitle {
    margin-top: 30%;
    letter-spacing: 0.2rem;
    font-size: 9pt;
  }

  .landing__title {
    font-size: 30pt;
    line-height: 36pt;
    font-weight: 600;
    margin: 0.3rem;
  }

  .landing__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0rem;
  }

  .landing__buttons a {
    display: block;
    margin: 0;
    margin-top: .8rem;
  }

  .landing__arrow {
    width: 15px;
  }
}